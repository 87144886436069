import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { DataProvider } from '../../../../sidemenu/dashboard/models/data-provider.model';
import { CreateFloor } from '../../../../sidemenu/planning/models/create-floor.model';
import { CreateLocation } from '../../../../sidemenu/planning/models/create-location.model';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { Affect } from './models/affect.model';
import { BuildingEvent } from './models/building-event.model';
import { BuildingStatus } from './models/building-status.model';
import { Building } from './models/building.model';
import { DisciplineFunctions } from './models/catalog-function.model';
import { ProductCatalogArticle } from './models/catalog-product.model';
import { CommissionPeripheralDevice } from './models/commission-peripheral-device.model';
import { CommissionResponse } from './models/commission-response.model';
import { CreateAffect } from './models/create-affect';
import { CreateDevice } from './models/create-device.model';
import { CreateFunctionalBlock } from './models/create-functional-block.model';
import { CreateProject } from './models/create-project.model';
import { DeviceFilter } from './models/device-filter.model';
import { DeviceSiblingsFilter } from './models/device-siblings-filter.model';
import { DeviceSiblings } from './models/device-siblings.model';
import { Device } from './models/device.model';
import { DisciplineProductFunctions } from './models/discipline-product-functions.model';
import { Floor } from './models/floor.model';
import { FunctionalBlock } from './models/functional-block.model';
import { JoinDevice } from './models/join-device.model';
import { JoinResponse } from './models/join-status.model';
import { LocationType } from './models/location-type.enum';
import { Location } from './models/location.model';
import { MonthlyConsumption } from './models/monthly-consumption.model';
import { PatchSerialNumber } from './models/patch-serial-number.model';
import { PlannedDevice } from './models/planned-device.model';
import { Product } from './models/product.model';
import { Project } from './models/project.model';
import { SetupFormModel } from './models/setup-form.model';
import { SubscriptionLimitData } from './models/subscription-limit-data.model';
import { ThingUpdateResponse } from './models/thing-update-response.model';
import { UpdateDevice } from './models/update-device.model';
import { UpdateEdgeConfig } from './models/update-edge-config.model';
import { UpdateEdgeDevice } from './models/update-edge-device.model';
import { UpdateForm } from './models/update-form.model';
import { UpdateFunctionalBlock } from './models/update-functional-block.model';
import { UpdateProject } from './models/update-project.model';
import { ValueWithTimestamp } from './models/value-with-timestamp';
import { FloorWithLocations } from '../../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import { CustomerLimitData } from './models/customer-limit-data.model';

@Injectable({ providedIn: ApiClientModule })
export class LcasApiClientService {
  constructor(
    private httpClient: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  getBackendVersion(): Observable<string> {
    return this.httpClient
      .get<{
        version: string;
      }>(`${environment.apiURL}/${environment.apiUrlVersion}/me/version`)
      .pipe(map(({ version }) => version));
  }

  getCustomerSubscriptionLimits(
    subscriptionId: string,
  ): Observable<SubscriptionLimitData> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<SubscriptionLimitData>(
        `${environment.apiURL}/${environment.apiUrlVersion}/customers/${store.customer?.id}/read-model/limits/${subscriptionId}`,
      ),
    );
  }

  getAllCustomerSubscriptionLimits(): Observable<CustomerLimitData> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<CustomerLimitData>(
        `${environment.apiURL}/${environment.apiUrlVersion}/customers/${store.customer?.id}/read-model/limits`,
      ),
    );
  }

  patchEdgeConfig(deviceId: string, dto: UpdateEdgeConfig): Observable<Device> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Device>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/${deviceId}/edge-config`,
        dto,
      ),
    );
  }

  updateEdgeDevice(
    edgeDeviceId: string,
    dto: UpdateEdgeDevice,
  ): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<void>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge/${edgeDeviceId}/deviceData`,
        dto,
      ),
    );
  }

  postBuilding(partitionId: string, buildingId: string): Observable<Building> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Building>(
        `${environment.apiURL}/${environment.apiUrlVersion}/customers/${store.customer?.id}/partitions/${partitionId}/buildings`,
        { id: buildingId },
      ),
    );
  }

  getLocations(
    floorId: string,
    locationType?: LocationType,
  ): Observable<Location[]> {
    return this.apiHelperService
      .callAndAddStore((store) =>
        this.httpClient.get<Location[]>(
          `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}/locations`,
          locationType ? { params: { type: locationType } } : undefined,
        ),
      )
      .pipe(map((locations) => sortBy(locations, 'name')));
  }

  getLocation(floorId: string, locationId: string): Observable<Location> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Location>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}/locations/${locationId}`,
      ),
    );
  }

  getDevice(deviceId: string): Observable<PlannedDevice> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<PlannedDevice>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/${deviceId}`,
      ),
    );
  }

  getDevices(filters: DeviceFilter): Observable<PlannedDevice[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<PlannedDevice[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices`,
        { params: filters as HttpParams },
      ),
    );
  }

  getFunctionCompatibleDevices(
    locationId: string,
    functionId: string,
  ): Observable<PlannedDevice[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<PlannedDevice[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/functionCompatible`,
        { params: { locationId, functionId } },
      ),
    );
  }

  hasSubordinateDevices(deviceId: string): Observable<boolean> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<boolean>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/${deviceId}/subordinateDevices`,
      ),
    );
  }

  patchDevice(device: UpdateDevice): Observable<Device> {
    const { id, ...updateDevice } = device;
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Device>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/${id}`,
        updateDevice,
      ),
    );
  }

  getDeviceSiblings(
    deviceId: string,
    filters: DeviceSiblingsFilter,
  ): Observable<DeviceSiblings> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<DeviceSiblings>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/${deviceId}/siblings`,
        { params: filters },
      ),
    );
  }

  commissionHeatMeter(
    deviceId: string,
    dto: PatchSerialNumber,
  ): Observable<Device> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Device>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/${deviceId}/serial-number`,
        {
          ...dto,
          edgeDeviceId: store.edgeDeviceId,
          edgeConfigId: store.edgeConfigId,
        },
      ),
    );
  }

  getFloor(floorId: string): Observable<Floor> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Floor>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}`,
      ),
    );
  }

  getFloors(): Observable<Floor[]> {
    return this.apiHelperService
      .callAndAddStore((store) =>
        this.httpClient.get<Floor[]>(
          `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors`,
        ),
      )
      .pipe(map((floors) => sortBy(floors, 'name')));
  }

  getFloorsWithLocations(): Observable<FloorWithLocations[]> {
    return this.apiHelperService
      .callAndAddStore((store) =>
        this.httpClient.get<FloorWithLocations[]>(
          `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors?includeRooms=true`,
        ),
      )
      .pipe(map((floors) => sortBy(floors, 'name')));
  }

  updateFloor(floor: Floor): Observable<Floor> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Floor>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floor.id}`,
        { name: floor.name, eTag: floor.eTag },
      ),
    );
  }

  postFloor(floor: CreateFloor): Observable<Floor> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Floor>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors`,
        floor,
      ),
    );
  }

  deleteFloor(floorId: string): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.delete<void>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}`,
      ),
    );
  }

  postLocation(
    floorId: string,
    location: CreateLocation,
  ): Observable<Location> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Location>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}/locations`,
        location,
      ),
    );
  }

  updateLocation(parameters: {
    locationId: string;
    floorId: string;
    name: string;
    eTag: number;
  }): Observable<Location> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Location>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${parameters.floorId}/locations/${parameters.locationId}`,
        { name: parameters.name, eTag: parameters.eTag },
      ),
    );
  }

  deleteLocation(floorId: string, locationId: string): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.delete<void>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/floors/${floorId}/locations/${locationId}`,
      ),
    );
  }

  postProject(project: CreateProject): Observable<Project> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Project>(
        `${environment.apiURL}/${environment.apiUrlVersion}/customers/${store.customer?.id}/projects`,
        project,
      ),
    );
  }

  getProjects(): Observable<Project[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Project[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/customers/${store.customer?.id}/projects`,
      ),
    );
  }

  getProject(projectId: string): Observable<Project> {
    return this.httpClient.get<Project>(
      `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${projectId}`,
    );
  }

  patchProject(
    projectId: string,
    project: Partial<UpdateProject>,
  ): Observable<Project> {
    return this.httpClient.patch<Project>(
      `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${projectId}`,
      project,
    );
  }

  getDeviceCatalog(): Observable<ProductCatalogArticle[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<ProductCatalogArticle[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/product-catalog/latest`,
      ),
    );
  }

  getProducts(): Observable<Product[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Product[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/products`,
      ),
    );
  }

  getProductFunctions(): Observable<DisciplineProductFunctions[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<DisciplineProductFunctions[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/products/functions`,
      ),
    );
  }

  patchProduct(product: Partial<Product>): Observable<Product> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Product>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${
          store.project?.id
        }/buildings/${store.building?.id}/products/${encodeURIComponent(
          product.productCatalogId || '',
        )}`,
        product,
      ),
    );
  }

  postProduct(product: Partial<Product>): Observable<Product> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Product>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/products`,
        { ...product, version: { model: product.version } },
      ),
    );
  }

  deleteProduct(product: Partial<Product>): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.delete(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${
          store.project?.id
        }/buildings/${store.building?.id}/products/${encodeURIComponent(
          product.productCatalogId || '',
        )}`,
      ),
    );
  }

  postDevices(dto: CreateDevice): Observable<Device[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Device[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices`,
        dto,
      ),
    );
  }

  postMultipleTypesOfDevices(dto: CreateDevice[]): Observable<Device[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Device[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/multipleTypes`,
        dto,
      ),
    );
  }

  deleteDevice(deviceId: string, force?: boolean): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.delete<void>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/devices/${deviceId}`,
        { body: { force } },
      ),
    );
  }

  patchFormDevice(
    peripheralDeviceId: string,
    dto: UpdateForm,
  ): Observable<Device> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Device>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/devices/${peripheralDeviceId}/forms`,
        dto,
      ),
    );
  }

  edgeReplaced(): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<unknown>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge-replaced`,
        {},
      ),
    );
  }

  commissionPeripheralDevice(
    peripheralDeviceId: string,
    dto: CommissionPeripheralDevice,
  ): Observable<Device> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Device>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/${peripheralDeviceId}/commission-peripheral`,
        {
          ...dto,
          edgeDeviceId: store.edgeDeviceId,
          edgeConfigId: store.edgeConfigId,
        },
      ),
    );
  }

  commissionFunction(
    functionalBlockId: string,
    eTag: number,
  ): Observable<FunctionalBlock> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<FunctionalBlock>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/${functionalBlockId}/commission-function`,
        {
          eTag,
          edgeDeviceId: store.edgeDeviceId,
          edgeConfigId: store.edgeConfigId,
        },
      ),
    );
  }

  joinDevice(dto: JoinDevice): Observable<CommissionResponse> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<CommissionResponse>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge/${store.edgeConfigId}/join`,
        dto,
      ),
    );
  }

  getJoinDeviceStatus(taskId: string): Observable<JoinResponse> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<JoinResponse>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge/${store.edgeConfigId}/join-status/${taskId}`,
      ),
    );
  }

  getDisciplinesAndFunctionsCatalog(): Observable<DisciplineFunctions[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<DisciplineFunctions[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/product-catalog/latest-disciplines-functions`,
      ),
    );
  }

  getBuildingStatus(): Observable<BuildingStatus[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<BuildingStatus[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.customerId}/building/status`,
      ),
    );
  }

  getBuildingEvents(): Observable<BuildingEvent[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<BuildingEvent[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/building/events`,
      ),
    );
  }

  getMonthlyHeatingEnergyConsumption(): Observable<MonthlyConsumption[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<MonthlyConsumption[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/metrics/consumption/heating-energy/monthly`,
      ),
    );
  }

  getMonthlyCoolingEnergyConsumption(): Observable<MonthlyConsumption[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<MonthlyConsumption[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/metrics/consumption/cooling-energy/monthly`,
      ),
    );
  }

  getTemperatureHistory(): Observable<ValueWithTimestamp[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<ValueWithTimestamp[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/metrics/temperature/outside/history`,
      ),
    );
  }

  getHeatCounterDeltaHistory(): Observable<ValueWithTimestamp[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<ValueWithTimestamp[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/metrics/counter-value/heat/delta/history`,
      ),
    );
  }

  getMonthlyElectricityConsumption(): Observable<MonthlyConsumption[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<MonthlyConsumption[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/metrics/consumption/electricity/monthly`,
      ),
    );
  }

  getFunctions(disciplineId?: string): Observable<FunctionalBlock[]> {
    const options = disciplineId
      ? { params: { discipline: disciplineId } }
      : undefined;
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<FunctionalBlock[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions`,
        options,
      ),
    );
  }

  getFunction(functionId: string): Observable<FunctionalBlock> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<FunctionalBlock>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions/${functionId}`,
      ),
    );
  }

  postFunction(
    functionalBlock: CreateFunctionalBlock,
  ): Observable<FunctionalBlock> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<FunctionalBlock>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions`,
        functionalBlock,
      ),
    );
  }

  patchFunction(
    functionalBlock: UpdateFunctionalBlock,
  ): Observable<FunctionalBlock> {
    const { id, ...updateFunctionalBlock } = functionalBlock;
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<FunctionalBlock>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions/${id}`,
        updateFunctionalBlock,
      ),
    );
  }

  deleteFunction(functionalBlockId: string, force?: boolean): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.delete<void>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions/${functionalBlockId}`,
        { body: { force } },
      ),
    );
  }

  patchFunctionForm(
    functionId: string,
    dto: UpdateForm,
  ): Observable<FunctionalBlock> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<FunctionalBlock>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/functions/${functionId}/forms`,
        dto,
      ),
    );
  }

  getAffect(functionId: string): Observable<Affect> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<Affect>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/affects/${functionId}`,
      ),
    );
  }

  patchAffect(functionId: string, affect: Affect): Observable<Affect> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<Affect>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/affects/${functionId}`,
        affect,
      ),
    );
  }

  postAffect(functionId: string, affect: CreateAffect): Observable<Affect> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<Affect>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/affects/${functionId}`,
        affect,
      ),
    );
  }

  getCustomerIdByPartitionId(
    partitionId: string,
  ): Observable<{ id: string; sharedWith?: string[] }> {
    return this.httpClient.get<{ id: string }>(
      `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${partitionId}/customer`,
    );
  }

  getDataProvidersByWidget(widgetType: string): Observable<DataProvider[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<DataProvider[]>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/widgets/latest/${widgetType}/data-providers`,
      ),
    );
  }

  checkConsistency(): Observable<boolean> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<boolean>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge/${store.edgeConfigId}/system-check/consistency-check`,
      ),
    );
  }

  solveInconsistencies(): Observable<boolean> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<boolean>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/commissioning/edge/${store.edgeConfigId}/system-check/solve-inconsistencies`,
      ),
    );
  }

  updateDeviceThing(
    deviceId: string,
    device: CommissionPeripheralDevice,
    newSetupForm: SetupFormModel,
  ): Observable<ThingUpdateResponse> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<ThingUpdateResponse>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/things/device/${deviceId}`,
        {
          device: {
            ...device,
            edgeDeviceId: store.edgeDeviceId,
            edgeConfigId: store.edgeConfigId,
          },
          newSetupForm,
        },
      ),
    );
  }

  updateFunctionThing(
    functionId: string,
    eTag: number,
    newSetupForm: SetupFormModel,
  ): Observable<ThingUpdateResponse> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<ThingUpdateResponse>(
        `${environment.apiURL}/${environment.apiUrlVersion}/partitions/${store.project?.id}/buildings/${store.building?.id}/things/function/${functionId}`,
        {
          functionalBlock: {
            eTag,
            edgeDeviceId: store.edgeDeviceId,
            edgeConfigId: store.edgeConfigId,
          },
          newSetupForm,
        },
      ),
    );
  }
}
